<template>
  <div>
    <div class="subscriber">
      <div class="subscriber-profile-info">
        <div class="subscriber-profile-info-alpha">
          <div class="subscriber-profile-info-alpha-profileImage">
            <img
              class="subscriber-profile-info-alpha-profileImage-image"
              v-if="subscriberProfileDetails ? subscriberProfileDetails.picture : subscriberDetails ? subscriberDetails.picture : null"
              :src="subscriberProfileDetails ? subscriberProfileDetails.picture : subscriberDetails ? subscriberDetails.picture : null"
              alt
            />
            <img class="subscriber-profile-info-alpha-profileImage-image" v-else :src="displayPic" alt />
          </div>
          <p class="subscriber-profile-info-alpha-subscribername">
            {{ getSubscriberProfileName }}
            <span v-if="subscriberid === profileid">
              <img src="@/assets/icons/Admin.svg" alt />
            </span>
          </p>
          <p v-if="subscriberDetails.email" class="subscriber-profile-info-alpha-subscriberemail">
            {{ subscriberDetails.email }}
          </p>

          <br v-if="subscriberDetails.mobileno" />

          <p v-if="subscriberDetails.mobileno" class="subscriber-profile-info-alpha-subscriberemail">
            {{ subscriberDetails.mobileno }}
          </p>
        </div>

        <div class="subscriber-profile-info-beta">
          <div class="subscriber-profile-info-beta-common location">
            <img src="@/assets/icons/Location.svg" alt />
            <p class="subscriber-profile-info-beta-common-info">
              {{ $t(subscriberDetails.subscribercountry) }}
            </p>
          </div>

          <div class="subscriber-profile-info-beta-common gender" v-if="getProfileGender">
            <img src="@/assets/icons/Name.svg" alt />
            <p class="subscriber-profile-info-beta-common-info">
              {{ $t(getProfileGender) }}
            </p>
          </div>

          <div class="subscriber-profile-info-beta-common dob" v-if="subscriberAgeRange">
            <img src="@/assets/icons/Calender-1.svg" alt />
            <!-- <p class="subscriber-profile-info-beta-common-info">Age</p> -->
            <!-- <p
            class="subscriber-profile-info-beta-common-info"
          >{{ displayDateFormat(subscriberDetails.dob) }}</p>-->
            <p dir="ltr" class="subscriber-profile-info-beta-common-info no-padding">
              <span>{{ subscriberAgeValue }}</span>
              {{ $t("Years") }}
            </p>
          </div>
        </div>
        <div class="subscriber-profile-info-mutable">
          <p class="popup-methods" @click="editSubscriber('Update')">
            {{ $t("edit profile") }}
          </p>
          <p v-if="showChangePasswordButton" class="popup-methods" @click="toggleChangePassword(true)">
            {{ $t("change password") }}
          </p>
        </div>
      </div>

      <!-- <div class="subscriber-related" v-if="!providerName === 'Noor Play'">
        <div class="subscriber-related-placeholder" v-if="!isSubProfiles">
          <div class="subscriber-related-placeholder-card" v-for="(item, index) in 2" :key="index">
            <div class="subscriber-related-placeholder-card-icon loading"></div>
            <div class="subscriber-related-placeholder-card-info">
              <p class="subscriber-related-placeholder-card-info-title loading"></p>
              <p class="subscriber-related-placeholder-card-info-type loading"></p>
            </div>
          </div>
        </div>
        <div class="subscriber-related-addprofile" v-if="isSubProfiles && subProfiles.length === 0">
          <p class="subscriber-related-addprofile-title">
            {{ $t("wish to create a subprofile") }}
          </p>
          <button
            class="button-primary subscriber-related-addprofile-btn"
            :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
            @click="toggleProfilePopup(true, 'Create')"
          >
            + {{ $t("add profile") }}
          </button>
        </div>
        <div v-if="isSubProfiles && subProfiles.length > 0">
          <div class="subscriber-related-header">
            <p class="subscriber-related-header-title">
              {{ $t("other profiles") }}
            </p>
            <p class="subscriber-related-header-title cursor" @click="toggleProfilePopup(true, 'Create')">
              {{ $t("add profile") }} +
            </p>
          </div>

          <div class="subscriber-related-profiles">
            <div class="subscriber-related-profiles-list" v-for="(profile, index) in subProfiles" :key="index">
              <div class="subscriber-related-profiles-list-profileIcon">
                <img v-if="profile.picture" class="sub-imgs" :src="profile.picture" alt />
                <img v-else class="sub-imgs" :src="displayPic" alt />
              </div>
              <div class="subscriber-related-profiles-list-profileInfo">
                <div class="subscriber-related-profiles-list-profileInfo-list">
                  <div class="subscriber-related-profiles-list-profileInfo-list-container">
                    <p
                      class="subscriber-related-profiles-list-profileInfo-list-container-fullname"
                      v-if="profile.profilename.length <= 8"
                    >
                      {{ profile.profilename }}
                    </p>
                    <p
                      class="subscriber-related-profiles-list-profileInfo-list-container-fullname"
                      v-if="profile.profilename.length > 8"
                    >
                      {{ profile.profilename.substring(0, 8) + "..." }}
                    </p>
                    <p
                    class="subscriber-related-profiles-list-profileInfo-container-email"
                  >{{ profile.gender }}</p>
                  </div>
                  <div class="subscriber-related-profiles-list-profileInfo-actions action-btns">
                    <img :src="editIcon" @click="editProfile(profile, 'Update')" alt />
                    <img :src="deleteIcon" @click="deleteProfile(profile)" alt />
                  </div>
                </div>
                <button
                  class="button-primary subscriber-related-profiles-list-switch enable-switch"
                  :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
                  @click="switchProfile(profile)"
                >
                  {{ $t("switch profile") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Change Profile and Edit profile Popup -->
      <transition>
        <div>
          <ChangePassword v-if="showChangePassword" :closePopup="() => toggleChangePassword(false)"></ChangePassword>
          <profilePopup
            v-if="showProfilePopup"
            :popupType="popupType"
            :detail="profileDetail ? profileDetail : {}"
            @refreshList="getUserInfo"
            :closePopup="() => toggleProfilePopup(false)"
          ></profilePopup>
          <confirmation
            v-if="showConfirmation"
            :deleteInfo="deleteInfo"
            @deleteConfirm="getUserInfo"
            @refreshProfilesList="getUserInfo"
            :closePopup="() => toggleConfirmation(false)"
          ></confirmation>
        </div>
      </transition>
    </div>

    <!-- The Manage Profile Container -->
    <div v-if="subscriberid === profileid && appConfig.featureEnabled.isMultipleProfileEnabled" :class="[
          localDisplayLang === 'ara' ? 'manageProfileContainerArabic' : 'manageProfileContainer',
        ]" 
    >
      <div class="manageProfiles">
        <p class="manageProfilesText">{{$t("manage profiles")}}</p>
        <div v-for="userProfile in profilesList" :key="userProfile.profileid" class="profiles">
          <div class="profileDetails">
            <img v-if="!userProfile.picture" src="@/assets/icons/ProfileDp.png" alt="" class="profileImage" />
            <img v-if="userProfile.picture" :src="userProfile.picture" alt="" class="profileImage" />
            <div>
              <p class="profileName">{{ userProfile.profilename }}</p>
              <p v-if="subscriberid === userProfile.profileid" class="profileBadge">{{ $t("admin") }}</p>
							<p v-if="userProfile.kidsmode === 'YES'" class="profileBadge">{{ $t("kids") }}</p>
							<!-- <p v-if="subscriberid === userProfile.profileid" class="profileBadge">{{ $t("admin") }}</p> -->
            </div>
          </div>
          <div>
            <p v-if="subscriberid !== userProfile.profileid" @click="deleteProfile(userProfile)" class="editProfile">{{$t("delete")}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import { mapActions, mapGetters } from "vuex";
import { _providerId, _projectName } from "@/provider-config.js";
import { setUserPropertiesFunc } from '@/utilities';

export default {
  data() {
    return {
      subscriberDetails: null,
      showChangePassword: false,
      subProfiles: [],
      displayPic: require("@/assets/icons/ProfileDp.png"),
      editIcon: require("@/assets/icons/Edit.svg"),
      deleteIcon: require("@/assets/icons/Trash.svg"),
      profileSectionLoader: false,
      isSubProfiles: false,
      showProfilePopup: false,
      showConfirmation: false,
      popupType: null,
      profile: null,
      subscriber: null,
      profileDetail: null,
      deleteInfo: null,
      subscriberAgeRange: null,
      providerUniqueId: _providerId,
      providerName: _projectName,
      localDisplayLang:"",
      profilesList: null,
      subscriberProfileDetails: null,
      loginType: null,
      subscriberAgeValue: null,
      subscriptionDetails: null,
			subscriptionDetail: null,
      isEditProfileEnableds: false
    };
  },

  computed: {
    ...mapGetters(["subscriberid", "profileid"]),

    showChangePasswordButton() {
      if(this.profileid === this.subscriberid && this.loginType !== "Google" && this.loginType !== 'FB') {
        return true;
      }
    },

    getSubscriberProfileName() {

      if (this.subscriberProfileDetails && this.subscriberProfileDetails.profilename && this.subscriberProfileDetails.profilename.length > 15) {
        return `${this.subscriberProfileDetails.profilename.substring(0, 15)}...`;
      } else if (this.subscriberProfileDetails && this.subscriberProfileDetails.profilename) {
        return this.subscriberProfileDetails.profilename;
      } else if (this.subscriberDetails.profilename && this.subscriberDetails.profilename.length > 15) {
        return `${this.subscriberDetails.profilename.substring(0, 15)}...`;
      } else {
        return this.subscriberDetails.profilename;
      }
    },

    getProfileGender () {

      if (this.subscriberProfileDetails && this.subscriberProfileDetails.gender) {
        return this.subscriberProfileDetails.gender;
      } 
      
      else if(this.subscriberDetails && this.subscriberDetails.gender) {
        return this.subscriberDetails.gender;
      } 
    }
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.getUserInfo();

    this.getCurrentProfileDetails();
  },

  mounted() {
    window.addEventListener("localstorage-updated", (event) => {
      this.subscriberDetails = JSON.parse(event.detail.storage).data;
      let subscriberAge = parseInt(this.subscriberDetails.age);

      this.getCurrentProfileDetails();

      this.getSubscriberAgeRange(subscriberAge);
		eventBus.$on('env-new-email-update', (newEmail) => {
      this.subscriberDetails.email = newEmail
    });
    });
  },
  methods: {
    ...mapActions(["listProfiles", "deleteProfile"]),

    getUserInfo() {
      this.isSubProfiles = false;
      if (localStorage.getItem("subscriberDetails")) {
        this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails")).data;
      }

      this.loginType = localStorage.getItem("login-payload") ? JSON.parse(localStorage.getItem("login-payload")).app : ""

      if (localStorage.getItem("subscriptionDetails")) {
        this.subscriptionDetails = JSON.parse(localStorage.getItem("subscriptionDetails"));
      }

      // if(this.subscriberid === this.profileid) {
        this.listUserProfiles();
      // }

      this.getCurrentProfileDetails();

      setUserPropertiesFunc(this.subscriberDetails, this.subscriptionDetails);
    },

    getCurrentProfileDetails() {
      this.subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails")
        ? JSON.parse(localStorage.getItem("subscriberProfileDetails"))
        : null;

      const ageOfSubscriber = this.subscriberProfileDetails ? this.subscriberProfileDetails.age : "";
      if(ageOfSubscriber) {
        this.getSubscriberAgeRange(parseInt(ageOfSubscriber));
        this.subscriberAgeValue = parseInt(ageOfSubscriber);
      }
    },

    listUserProfiles() {
      this.listProfiles()
        .then((data) => {
          this.profilesList = data.data.data;

          this.profilesList.forEach(profile => {
            if(profile.profileid === this.profileid) {
              this.subscriberProfileDetails = profile;

              console.log("subscriberProfileDetails" , this.subscriberProfileDetails);

              this.subscriberAgeValue = parseInt(this.subscriberProfileDetails.age);

              localStorage.setItem("subscriberProfileDetails", JSON.stringify(profile));
            }
          })
        })
        .catch((err) => {
          console.log("Error While fetching profiles -> ", err);
        });
    },

    getSubscriberAgeRange(data) {
      switch (true) {
        case data < 13:
          this.subscriberAgeRange = "<13";
          console.log("subscriberAgeRange", this.subscriberAgeRange);
          break;
        case data < 18:
          this.subscriberAgeRange = "13-17";
          console.log("subscriberAgeRange", this.subscriberAgeRange);
          break;
        case data < 31:
          this.subscriberAgeRange = "18-30";
          console.log("subscriberAgeRange", this.subscriberAgeRange);
          break;
        case data < 45:
          this.subscriberAgeRange = "31-45";
          console.log("subscriberAgeRange", this.subscriberAgeRange);
          break;
        case data > 45:
          this.subscriberAgeRange = "45+";
          console.log("subscriberAgeRange", this.subscriberAgeRange);
          break;
        default:
          break;
      }
    },

    switchProfile(profile) {
      console.log("profile", profile);
      if (profile.profilepin === "ENABLED") {
        eventBus.$emit("switchProfile", profile);
      } else {
        eventBus.$emit("enableOverlayLoader", true);

        let payload = {
          profileid: profile.profileid,
        };
        eventBus.$emit("switch-profile", payload);
      }
    },

    editSubscriber(value) {
      this.profileDetail = this.subscriberProfileDetails ? this.subscriberProfileDetails : this.subscriberDetails;
      this.toggleProfilePopup(true, value);
    },

    editProfile(data, value) {
      this.profileDetail = data;

      this.toggleProfilePopup(true, value);
    },

    deleteProfile(data) {
      this.toggleConfirmation(true);
      this.deleteInfo = data;
    },

    toggleChangePassword(state) {
      this.showChangePassword = state;
    },

    toggleConfirmation(state) {
      this.showConfirmation = state;
    },

    toggleProfilePopup(state, value) {
      if (!state) {
        this.profileDetail = {};
      }
      this.popupType = value;
      this.showProfilePopup = state;
    },
  },
  components: {
    ChangePassword: () =>
      import(/* webpackChunkName: "header" */ "@/components/Popups/ProfilePopups/ChangePassword.vue"),
    profilePopup: () => import(/* webpackChunkName: "header" */ "@/components/Popups/ProfilePopups/profilePopup.vue"),
    confirmation: () => import(/* webpackChunkName: "confirmation" */ "@/components/Popups/confirmation.vue"),
  },
  mixins: [Utility, googleAnalytics],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.subscriber {
  background-color: $clr-dark-gd4;
  font-family: $font-regular;
  border-radius: 1rem;
  // max-height: 100vh;
  &-profile-info {
    padding: 2.4rem 1.8rem;
    &-alpha {
      &-profileImage {
        margin-bottom: 0.6rem;
        width: 90px;
        &-image {
          width: 100%;
          height: 90px;
          border-radius: 50%;
        }
      }
      &-subscribername {
        color: $clr-light-gd3;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 0.3rem;
      }
      &-subscriberemail {
        color: $clr-light-gd3;
        font-size: 0.9rem;
				word-break: break-all;
      }
    }
    &-beta {
      color: $clr-light-gd3;
      opacity: 0.5;
      line-height: 30px;
      margin: 1.2rem 0rem;
      &-common {
        display: flex;
        img {
          margin-top: 0.5rem;
          margin-right: 8px;
          width: 15px;
          height: 15px;
        }
        &-info {
          font-size: 0.8rem;
          padding-right: 8px;
        }
        // .no-padding {
        //   padding-left: 0%;
        // }
      }
    }
    &-mutable {
      display: flex;
      justify-content: space-between;
      font-size: 0.7rem;
      color: $clr-light-gd3;
      font-weight: bold;
      cursor: pointer;
      .popup-methods {
        opacity: 0.5;
        &:hover {
          color: $btn-clr;
          // color: $clr-light-gd2; // for nammaflix
          opacity: 1;
        }
      }
    }
  }
  &-related {
    padding: 1.2rem 1.8rem;
    background-color: $clr-dark-gd5;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    &-placeholder {
      &-card {
        display: flex;
        align-items: center;
        background-color: $clr-dark-gd4;
        border-radius: 10px;
        margin: 0.5rem 0;
        padding: 0.5rem;
        &-icon {
          width: 20%;
          height: 40px;
          border-radius: 50%;
          background-color: #0d0d0d;
          overflow: hidden;
          margin-right: 10px;
        }
        &-info {
          &-title {
            height: 10px;
            width: 85px;
            background-color: #0d0d0d;
            margin-bottom: 5px;
            overflow: hidden;
          }
          &-type {
            height: 10px;
            width: 40px;
            background-color: #0d0d0d;
            overflow: hidden;
          }
        }
      }
    }
    &-addprofile {
      &-title {
        font-size: 1rem;
        color: #efeff4;
        font-weight: bold;
        opacity: 0.5;
        font-family: $font-regular;
      }
      &-btn {
        width: 50%;
        margin: 20px 0;
        padding: 0.4rem;
        font-size: 0.7rem;
      }
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.5rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid $clr-dark-gd11;
      &-title {
        font-size: 0.7rem;
        color: $clr-light-gd3;
        font-weight: bold;
        opacity: 0.5;
        line-height: 30px;
        &:last-child {
          &:hover {
            color: $btn-clr;
            // color: $clr-light-gd2; // for nammaflix
            opacity: 1;
          }
        }
      }
      .cursor {
        cursor: pointer;
      }
    }
    &-profiles {
      &-list {
        display: flex;
        align-items: center;
        line-height: 15px;
        background-color: $clr-dark-gd4;
        border-radius: 10px;
        margin: 0.5rem;
        padding: 1rem;
        &-switch {
          display: none;
          padding: 0.1rem 0.5rem;
          font-size: 0.5rem;
          margin-top: 5px;
          width: 60%;
        }
        &:hover {
          background-color: $clr-dark-gd11;
          .action-btns {
            display: block;
          }
          .enable-switch {
            display: block;
          }
        }
        &-profileIcon {
          width: 45px;
          .sub-imgs {
            width: 100%;
            height: 38px;
            border-radius: 50%;
          }
        }
        &-profileInfo {
          width: 100%;
          padding-left: 0.7rem;
          &-list {
            display: flex;
            justify-content: space-between;
            width: 100%;
            &-container {
              &-fullname {
                font-size: 0.8rem;
                color: $clr-light-gd3;
                font-weight: bold;
              }
              &-email {
                font-size: 0.6rem;
                color: $clr-light-gd3;
                font-weight: bold;
                opacity: 0.5;
              }
            }
          }
          .action-btns {
            display: none;
          }
          &-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            img {
              width: 27px;
              height: 15px;
              cursor: pointer;
              // display: none;
            }
          }
        }
      }
    }
  }
}

.manageProfileContainer {
  margin: 1rem 0;
  height: fit-content;
  border-radius: 1rem;
  padding: 1rem;
  background-color: #212121;
  .manageProfiles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .manageProfilesText {
      color: #ffffff;
      font-family: $font-regular;
      font-size: 1rem;
      letter-spacing: 0;
      line-height: 12px;
      font-weight: 600;
      padding: 1rem;
    }

    .profiles {
      margin: 0.75rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      .profileDetails {
        display: flex;
        .profileImage {
          width: 40px;
          height: 40px;
					border-radius: 50%;
        }
        .profileName {
          margin: 0.25rem;
          color: $clr-dark-gd10;
          margin-left: 1.5rem;
          font-family: $font-regular;
          font-size: 0.8rem;
          letter-spacing: 0;
          line-height: 12px;
          font-weight: 600;
        }

        .profileBadge {

          margin-top: 0.8rem;
          color: $clr-dark-gd10;
          margin-left: 1.5rem;
          font-family: $font-regular;
          letter-spacing: 0;
          line-height: 12px;
          font-weight:300;
          font-size: 0.75rem;

        }
      }

      .editProfile {
        margin-bottom: 1.2rem;
        color: $clr-dark-gd10;
        margin-left: 1.5rem;
        font-family: $font-regular;
        font-size: 0.8rem;
        letter-spacing: 0;
        line-height: 12px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

.manageProfileContainerArabic {
  margin: 1rem 0;
  height: fit-content;
  border-radius: 1rem;
  padding: 1rem;
  background-color: #212121;
  .manageProfiles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .manageProfilesText {
      color: #ffffff;
      font-family: $font-regular;
      font-size: 1rem;
      letter-spacing: 0;
      line-height: 12px;
      font-weight: 600;
      padding: 1rem;
    }

    .profiles {
      margin: 0.75rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      .profileDetails {
        display: flex;
        .profileImage {
          width: 40px;
          height: 40px;
					border-radius: 50%;
        }
        .profileName {
          margin: 0.25rem;
          color: $clr-dark-gd10;
          margin-right: 1.5rem;
          font-family: $font-regular;
          font-size: 0.8rem;
          letter-spacing: 0;
          line-height: 12px;
          font-weight: 600;
        }

        .profileBadge {

          margin-top: 0.8rem;
          color: $clr-dark-gd10;
          margin-right: 1.5rem;
          font-family: $font-regular;
          letter-spacing: 0;
          line-height: 12px;
          font-weight:300;
          font-size: 0.75rem;

        }
      }

      .editProfile {
        margin-bottom: 1.2rem;
        color: $clr-dark-gd10;
        margin-right: 1.5rem;
        font-family: $font-regular;
        font-size: 0.8rem;
        letter-spacing: 0;
        line-height: 12px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .subscriber {
    margin-bottom: 20px;
    &-related {
      &-profiles {
        &-list {
          &-switch {
            display: block;
            padding: 0.2rem;
            font-size: 10px;
          }
          &-profileIcon {
            width: 60px;
            .sub-imgs {
              height: 60px;
            }
          }
          &-profileInfo {
            &-list {
              &-container {
                &-fullname {
                  font-size: 1rem;
                }
              }
            }
          }
        }
        .action-btns {
          display: block;
        }
        &-actions {
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscriber {
    margin-bottom: 20px;
  }
}
</style>
